<template>
  <div>
    <div class="row clustering-container">
      <div class="col-md-3">
        <div class="sticky-top pt-3 hidden lg-block"
             @dragenter="targetCluster = looseItems"
             @dragleave="(ev) => ev.preventDefault()"
             @dragover="(ev) => ev.preventDefault()"
        >
          <div @click="addCluster" class="btn btn-success mb-2">
            Neuen Cluster hinzufügen
          </div>
          <br>
          <div @click="orderMode = !orderMode" class="btn btn-primary mb-4">
            {{ orderMode ? 'Ordnen beenden' : 'Cluster ordnen' }}
          </div>

          <h4>Nicht zugeordnete Items:</h4>

          <div class="row">
            <div v-for="(node, index) in looseItems" class="col-12" :key="index">
              <div class="p-1">
                <div class="border rounded p-2"
                     draggable="true"
                     :style="'opacity:' + (draggedItem === node ? '.3' : '1')"
                     @dragend="moveEl"
                     @dragstart="draggedItem = node; sourceCluster = rootNode.looseItems;"
                >
                  <div class="row">
                    <div class="col pr-0">
                      <InPlaceInput v-model="node.title" classes="naked form-control"></InPlaceInput>
                    </div>
                    <div class="col-auto pt-1 pl-0">
                      <i class="fal fa-arrows fa-pull-right mt-2"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <p v-if="!looseItems.length">
            Keine Items vorhanden.
          </p>
          <div v-if="draggedItem.id"
               @dragenter="(ev) => ev.preventDefault()"
               @dragend="(ev) => ev.preventDefault()"
               @dragleave="(ev) => ev.preventDefault()"
               @dragover="(ev) => ev.preventDefault()"
               class="add-overlay rounded"
          >
            <i class="fad fa-inbox-in fa-2x text-white"></i>
          </div>
        </div>
      </div>

      <div class="col border rounded py-3"
           @dragleave="(ev) => ev.preventDefault()"
           @dragover="(ev) => ev.preventDefault()"
           @dragenter="targetCluster = newCluster"
           @dragend="moveEl"
           :class="targetCluster === newCluster ? 'bg-light' : ''"
      >
        <div class="row"
             @dragleave="(ev) => ev.preventDefault()"
             @dragover="(ev) => ev.preventDefault()"
             @dragenter="(ev) => ev.preventDefault()"
        >
          <div v-for="(cluster, index) in newClusters"
               class="col-6 mb-4 h-100"
               :key="index"
               @dragenter="dragOrder(index)"
          >
            <BrainstormClusterItem v-if="cluster !== 'placeholder'"
                                   :cluster="cluster"
                                   :dragged-item="draggedItem"
                                   :is-target="targetCluster === cluster"
                                   :target-index="targetIndex"
                                   :minimized="orderMode"
                                   :mobile-dragging-active="mobileDraggingActive"
                                   @dragleave="(ev) => ev.preventDefault()"
                                   @dragover="(ev) => ev.preventDefault()"
                                   @dragItemStart="startDrag($event)"
                                   @dragItemBetween="dragOver($event)"
                                   @dragItemEnd="moveEl"
                                   @dragItemStartMobile="dragStartMobile($event)"
                                   @dragEndMobile="dragEndMobile($event)"
                                   @dragCancelMobile="dragCancelMobile"
                                   @add="addToCluster(cluster, index, $event)"
                                   @remove="removeFromCluster(cluster, $event)"
                                   @removeCluster="removeCluster(cluster)"
                                   @selectNode="$emit('selectNode', [index])"
            >
            </BrainstormClusterItem>
            <div v-else class="card h-100" @dragend="moveEl">
              <div class="card-body">
                Hier einfügen
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-if="targetCluster === newCluster">
          <div class="col-12">
            <div class="card"
                 @dragleave="(ev) => ev.preventDefault()"
                 @dragover="(ev) => ev.preventDefault()"
                 @click="addCluster"
            >
              <div class="card-body">
                <i class="fad fa-circle-plus"></i> Zu neuem Cluster machen
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrainstormClusterItem from "@/components/BrainstormClusterItem";
import InPlaceInput from "@/components/InPlaceInput";

export default {
  name: 'BrainstormClustering',
  components: {
    InPlaceInput,
    BrainstormClusterItem
  },
  directives: {},
  props: {
    rootNode: Object,
  },
  data() {
    return {
      newCluster: {
        title: "",
        children: [],
        looseItems: [],
      },
      draggedItem: {},
      sourceCluster: {},
      targetCluster: {},
      targetIndex: -1,
      mobileDraggingActive: false,
      orderMode: false
    }
  },
  computed: {
    newClusters() {
      /*let clusters = this.rootNode.children.filter(cluster => cluster !== "placeholder");
      if (this.sourceCluster === this.rootNode) {
        clusters.splice(this.targetIndex + 1, 0, "placeholder")
      }
      return clusters;*/
      return this.rootNode.children;
    },
    looseItems() {
      return this.rootNode.looseItems;
    },
  },
  watch: {
    /*sourceCluster(newVal) {
      console.log("sourceCluster", newVal.title)
    },
    targetCluster(newVal) {
      console.log("targetCluster", newVal.title || "neu")
    },
    targetIndex(newVal) {
      console.log("targetIndex", newVal)
    },*/
  },
  methods: {
    startDrag($event) {
      this.draggedItem = $event.item;
      this.sourceCluster = $event.cluster === "root" ? this.rootNode : $event.cluster;
    },
    dragOver($event) {
      this.targetCluster = $event.cluster;
      this.targetIndex = $event.index;
    },
    dragOrder(newIndex) {
      if (!this.orderMode) return;
      this.targetIndex = newIndex;
      if (this.sourceCluster === this.rootNode) {
        let index = this.rootNode.children.findIndex(n => n === this.draggedItem);
        this.rootNode.children.splice(index, 1);
        this.rootNode.children.splice(newIndex, 0, this.draggedItem);
      }
    },
    dragStartMobile($event) {
      this.draggedItem = $event.item;
      this.sourceCluster = $event.cluster === "root" ? this.rootNode : $event.cluster;
      this.mobileDraggingActive = true;
    },
    dragCancelMobile() {
      this.mobileDraggingActive = false;
    },
    dragEndMobile($event) {
      this.targetCluster = $event.cluster;
      this.targetIndex = $event.index;
      this.moveEl();
    },
    moveEl() {
      if (this.draggedItem === this.targetCluster || this.orderMode) {
        this.sourceCluster = {};
        this.draggedItem = {};
        this.targetCluster = {};
        this.targetIndex = -1;
        this.$forceUpdate();
        return;
      }

      if (this.sourceCluster !== this.looseItems) this.sourceCluster = this.sourceCluster.children;

      this.mobileDraggingActive = false;

      let index = this.sourceCluster.findIndex(n => n === this.draggedItem);
      this.sourceCluster.splice(index, 1);

      this.draggedItem = JSON.parse(JSON.stringify(this.draggedItem));

      if (this.targetCluster === this.newCluster) {
        // this.newCluster.children.push(this.draggedItem);
        // this.newClusters.splice(0, 0, JSON.parse(JSON.stringify(this.newCluster)));
        this.rootNode.children.splice(this.targetIndex, 0, JSON.parse(JSON.stringify(this.draggedItem)));
        this.newCluster = {
          title: "",
          children: [],
          looseItems: [],
        };

      } else if (this.targetCluster === this.looseItems) {
        this.rootNode.looseItems.push(this.draggedItem);

      } else {
        this.targetCluster.children.splice(this.targetIndex, 0, this.draggedItem);
      }

      this.sourceCluster = {};
      this.draggedItem = {};
      this.targetCluster = {};
      this.targetIndex = -1;

      this.$emit('update', {update: this.rootNode});
    },
    addCluster() {
      this.rootNode.children.push({
        title: "",
        children: []
      });
    },
    addToCluster(cluster, index, $event) {
      cluster = this.rootNode.children[index];
      cluster.children.push({
        title: $event,
        children: [],
        looseItems: [],
      });
      this.$emit('update', {update: this.rootNode});
    },
    removeFromCluster(cluster, $event) {
      let temp = cluster.children[$event];
      cluster.children.splice($event, 1);
      this.looseItems.push(temp)
      this.$emit('update', {update: this.rootNode});
    },
    removeCluster(cluster) {
      this.looseItems.concat(cluster);
      let index = this.rootNode.children.findIndex(n => n === cluster);
      this.rootNode.children.splice(index, 1);
      this.$emit('update', {update: this.rootNode});
    },
  },
  updated() {
    if (!this.looseItems) {
      this.rootNode.looseItems = [];
    }
  }
}
</script>

<style lang="scss" scoped>
  .clustering-container {
    position: relative;

    .sticky-top {
      min-height: 80vh;

      .add-overlay {
        position: absolute;
        top: 5rem;
        left: 0;
        right: 0;
        bottom: 0;
        background: var(--dark);
        opacity: .5;

        i.fad {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
</style>