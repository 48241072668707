<template>
  <div class="card"
       @dragenter="dragEnter($event, 0)"
       @dragleave="(ev) => ev.preventDefault()"
  >
    <div class="card-header"
         draggable="true"
         @dragstart="dragStart($event, cluster)"
    >
      <div class="row">
        <div class="col-auto">
          <span class="float-left mt-2">
            <i v-if="minimized" class="fal fa-arrows text-primary"></i>
            <i v-else class="fal fa-arrow-right text-primary clickable" @click="$emit('selectNode')"></i>
          </span>
        </div>
        <div class="col">
          <InPlaceInput v-model="cluster.title"
                        placeholder="Cluster benennen"
                        :forceEdit="!cluster.title"
                        classes="form-control"
                        class="font-bold"
          ></InPlaceInput>
        </div>
        <div class="col-auto">
          <span class="float-right mt-2">
            ({{ cluster.children.length }})
            <i v-if="false" class="fal fa-minus-circle text-danger clickable" @click="removeCluster"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="draggedItem !== cluster">
      <div v-if="minimized"
           class="ordering-overlay"
           @dragenter="(ev) => ev.preventDefault()"
           @dragover="(ev) => ev.preventDefault()"
           @dragleave="(ev) => ev.preventDefault()"
      ></div>
      <ul class="list-group">
        <template v-for="(child, childIndex) in cluster.children">
          <li v-if="!minimized && (mobileDraggingActive || (isTarget && targetIndex === childIndex))"
              @dragenter="dragEnter($event, childIndex)"
              @dragleave="(ev) => ev.preventDefault()"
              @dragover="(ev) => ev.preventDefault()"
              class="list-group-item"
              :key="childIndex + '-add'"
          >
            <button @click="dragEndMobile($event, childIndex)" class="btn btn-block btn-dark btn-sm">
              <i class="fad fa-arrow-circle-down"></i>
            </button>
          </li>
          <li :style="draggedItem === child ? 'opacity: .3' : ''"
              draggable="true"
              @dragstart="dragStart($event, child)"
              @dragend="dragEnd($event)"
              @dragenter="dragEnter($event, childIndex)"
              @dragleave="(ev) => ev.preventDefault()"
              @dragover="(ev) => ev.preventDefault()"
              class="list-group-item"
              :key="childIndex"
          >
            <InPlaceInput v-model="child.title"
                          placeholder="Item benennen"
                          :forceEdit="!child.title"
                          classes="naked"
                          class="w-75"
            ></InPlaceInput>
            <i class="fal fa-minus-circle text-danger float-right mt-1" @click="removeFromCluster(childIndex)"></i>
            <i v-if="!clientHasDragging" class="fal fa-arrows float-right mt-1 mr-1" @click="dragStartMobile($event, child)"></i>
          </li>
        </template>
        <li v-if="!minimized && (mobileDraggingActive || (isTarget && (targetIndex === cluster.children.length || !cluster.children.length)))"
            @dragenter="dragEnter($event, cluster.children.length)"
            @dragleave="(ev) => ev.preventDefault()"
            @dragover="(ev) => ev.preventDefault()"
            class="list-group-item"
        >
          <button @click="dragEndMobile($event, cluster.children.length)" class="btn btn-block btn-dark btn-sm">
            <i class="fad fa-arrow-circle-down"></i>
          </button>
        </li>
      </ul>
    </div>
    <div class="card-footer"
         @dragenter="dragEnter($event, cluster.children.length)"
         @dragleave="(ev) => ev.preventDefault()"
    >
      <input @change="add($event)" placeholder="Neues Item erstellen" class="form-control" />
    </div>
  </div>
</template>

<script>

import InPlaceInput from "@/components/InPlaceInput";
export default {
  name: 'BrainstormClusterItem',
  components: {InPlaceInput},
  directives: {},
  props: {
    cluster: Object,
    isTarget: Boolean,
    targetIndex: Number,
    draggedItem: Object,
    mobileDraggingActive: Boolean,
    minimized: Boolean
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  methods: {
    dragStart($event, child) {
      this.$emit('dragItemStart', { item: child, cluster: child === this.cluster ? "root" : this.cluster })
    },
    dragStartMobile($event, child) {
      if (this.draggedItem === child) {
        this.$emit('dragCancelMobile')
      }
      this.$emit('dragItemStartMobile', { item: child, cluster: this.cluster })
    },
    dragEnd($event) {
      if (this.minimized) return;
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('dragItemEnd')
    },
    dragEndMobile($event, index) {
      if (this.minimized) return;
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('dragEndMobile', { cluster: this.cluster, index: index })
    },
    dragEnter($event, index) {
      if (this.minimized) return;
      $event.preventDefault();
      $event.stopPropagation();
      this.$emit('dragItemBetween', { cluster: this.cluster, index: index })
    },
    dragEnterCard($event) {
      $event.preventDefault();
      if (this.minimized) return;
      $event.stopPropagation();
      if (!this.isTarget && this.targetIndex !== this.cluster.children.length) {
        this.$emit('dragItemBetween', {cluster: this.cluster, index: this.cluster.children.length})
      }
    },
    add($event) {
      if ($event.target.value) {
        this.$emit('add', $event.target.value);
        $event.target.value = "";
      }
    },
    removeFromCluster(index) {
      this.$emit('remove', index);
    },
    removeCluster() {
      this.$emit('removeCluster');
    },
  },
  beforeUpdate() {
  }
}
</script>

<style scoped>
  .card-body {
    position: relative;
  }
  .card-header .form-control {
    font-weight: 500;
    font-size: 1rem;
  }
  .ordering-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    opacity: .7;
    z-index: 1;
  }
</style>