<template>
  <div>
    <div v-if="mobileDraggingActive || (unmappedNodes.length > 0 && keys.length >= 1)"
         @dragenter="draggedOver = unmappedNodes"
         @dragleave="(ev) => ev.preventDefault()"
         @dragover="(ev) => ev.preventDefault()"
         class="my-3"
    >
      <h4>Nicht zugeordnete Items</h4>

      <button v-if="mobileDraggingActive" class="btn-dark btn-lg btn-block" style="opacity: .3" @click="dragEndMobile(unmappedNodes)">
        <i class="fad fa-inbox-in fa-2x"></i>
      </button>

      <div class="row">
        <div v-for="(node, index) in unmappedNodes" :key="index" class="col-auto">
          <div class="btn btn-dark m-1"
               :style="'opacity:' + (draggedEl === node ? '.3' : '1')"
               draggable="true"
               @dragstart="draggedEl = node"
               @dragend="mapDraggedNode($event)"
               @click="dragStartMobile(node)"
          >
            <!--@click="selectNode(node)"-->
            <p class="mb-0 p-0">{{ node.title }}</p>
            <div v-if="matrix.d.active && !matrix.d.numeric && node[matrix.d.title]" class="dots">
              <span v-for="d in Array.from({length: node[matrix.d.title]},(x, i) => i)" class="dot" :key="d"></span>
            </div>
            <span v-else-if="matrix.d.active && node[matrix.d.title]" class="text-left small m-0 p-0">
            {{ matrix.d.title }}: {{ node[matrix.d.title] }}
          </span>
          </div>
        </div>
      </div>
    </div>

    <table v-if="(matrix.x.active && matrix.x.title) || (matrix.y.active && matrix.y.title)" class="table text-center table-sm">
      <tbody>
      <tr>
        <td :colspan="mapX.range + 2">{{ matrix.x.title }}</td>
        <td class="y-label">{{ matrix.y.title }}</td>
      </tr>
      <tr v-for="(yMap, yIndex) in Array.from({length: mapY.range},(x, i) => matrix.y.invert ? (i+mapY.lower) : (mapY.upper-i))"
          :key="yMap">
        <td width="28px">
          {{ yMap }}
        </td>
        <td v-for="(xMap, xIndex) in Array.from({length: mapX.range},(x, i) => matrix.x.invert ? (mapX.upper-i) : (i+mapX.lower))"
            :style="'background:' + getBgColor(xIndex, mapY.upper-yIndex)"
            :class="
              (xMap === Math.floor(mapX.range/2) ? (matrix.x.invert ? ' border-left' : ' border-right') : '') +
              (yMap === Math.floor(mapY.range/2) ? (matrix.y.invert ? ' border-bottom' : ' border-top') : '')"
            :key="xMap"
            @dragenter="draggedOver = { x: xMap, y: yMap }"
            @dragleave="(ev) => ev.preventDefault()"
            @dragover="(ev) => ev.preventDefault()"
        >
          <div v-for="(node, index) in mapNodes(xMap, yMap)"
               class="map-node text-white rounded px-2 py-1 mb-1"
               :class="(matrix.c.active ? '' : 'bg-dark') + (matrix.d.active ? ' scored' : '')"
               :style="'background:' + getNodeColor(node) + ';opacity:' + (draggedEl === node ? '.3' : '1')"
               :key="index"
               draggable="true"
               @dragstart="draggedEl = node"
               @dragend="mapDraggedNode"
               @click="dragStartMobile(node)"
          >
            <p class="mb-0 p-0">{{ node.title }}</p>
            <div v-if="matrix.d.active && !matrix.d.numeric && node[matrix.d.title]" class="dots">
              <span v-for="d in Array.from({length: node[matrix.d.title]},(x, i) => i)" class="dot" :key="d"></span>
            </div>
            <span v-else-if="matrix.d.active && node[matrix.d.title]" class="text-left small m-0 p-0">
              {{ matrix.d.title }}: {{ node[matrix.d.title] }}
            </span>
            <div v-if="matrix.d.active" class="hover-menu rounded">
              <div class="btn-group block">
                <button @click="node[matrix.d.title]--" class="btn btn-dark btn-sm px-0">
                  <i class="fad fa-minus-circle"></i>
                </button>
                <button @click="node[matrix.d.title]++" class="btn btn-dark btn-sm px-0">
                  <i class="fad fa-plus-circle"></i>
                </button>
              </div>
            </div>
            <div class="hover-overlay-mobile">
              <i class="fal fa-arrows"></i>
            </div>
          </div>

          <div v-if="mobileDraggingActive || showMapAdd(xMap, yMap)"
               @click="dragEndMobile({ x: xMap, y: yMap })"
               class="map-add btn btn-block btn-dark btn-sm"
          >
            <i v-if="draggedEl[matrix.x.title] !== xMap || draggedEl[matrix.y.title] !== yMap" class="fad fa-plus-circle"></i>
            <i v-else class="fad fa-times-circle fa-2x text-danger"></i>
          </div>
        </td>
      </tr>

      <tr>
        <td></td>
        <td v-for="xIndex in Array.from({length: mapX.range}, (x, i) => matrix.x.invert ? (mapX.upper-i) : (i+mapX.lower))" :key="xIndex">
          {{ xIndex }}
        </td>
      </tr>
      </tbody>
    </table>

    <div class="card">
      <div class="card-body">
        <div v-if="keys.length >= 1" class="row mb-3">
          <div class="col-md-6 col-lg dimension-settings">
            <h4 class="form-check">
              <input id="x-axis" type="checkbox" v-model="matrix.x.active" class="form-check-input mt-2">
              <label for="x-axis" class="form-check-label">x-Achse</label>
            </h4>
            <div v-if="matrix.x.active">
              <div class="form-row mb-1">
                <div class="col-6">
                  <input id="x-min" type="number" min="-20" max="20" v-model.number="matrix.x.min" placeholder="Min" class="form-control">
                </div>
                <div class="col-6">
                  <input id="x-max" type="number" min="-20" max="20" v-model.number="matrix.x.max" placeholder="Max" class="form-control">
                </div>
              </div>
              <select v-model="matrix.x.title" class="form-control">
                <option value="" disabled="disabled">Dimension wählen</option>
                <option v-for="(key, index) in keys" :value="key.title" :key="index">{{ key.title }}</option>
              </select>
              <div class="form-check">
                <input id="invert-x" type="checkbox" v-model="matrix.x.invert" class="form-check-input">
                <label for="invert-x" class="form-check-label">X Invertieren</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg dimension-settings">
            <h4 class="form-check">
              <input id="y-axis" type="checkbox" v-model="matrix.y.active" class="form-check-input mt-2">
              <label for="y-axis" class="form-check-label">y-Achse</label>
            </h4>
            <div v-if="matrix.y.active">
              <div class="form-row mb-1">
                <div class="col-6">
                  <input id="y-min" type="number" min="-50" max="50" v-model.number="matrix.y.min" placeholder="Min" class="form-control">
                </div>
                <div class="col-6">
                  <input id="y-max" type="number" min="-50" max="50" v-model.number="matrix.y.max" placeholder="Max" class="form-control">
                </div>
              </div>
              <select v-model="matrix.y.title" class="form-control">
                <option value="" disabled="disabled">Dimension wählen</option>
                <option v-for="(key, index) in keys" :value="key.title" :key="index">{{ key.title }}</option>
              </select>
              <div class="form-check">
                <input id="invert-y" type="checkbox" v-model="matrix.y.invert" class="form-check-input">
                <label for="invert-y" class="form-check-label">Y Invertieren</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg dimension-settings" :class="!matrix.x.active && !matrix.y.active ? 'disabled' : ''">
            <h4 class="form-check">
              <input id="dot" type="checkbox" v-model="matrix.d.active" class="form-check-input mt-2">
              <label for="dot" class="form-check-label">Punktedimension</label>
            </h4>
            <div v-if="matrix.d.active">
              <select v-model="matrix.d.title" class="form-control">
                <option value="" disabled="disabled">Dimension wählen</option>
                <option v-for="(key, index) in keys" :value="key.title" :key="index">{{ key.title }}</option>
              </select>
              <div class="form-check">
                <input id="dot-number" type="checkbox" v-model="matrix.d.numeric" class="form-check-input">
                <label for="dot-number" class="form-check-label">Als Zahl anzeigen</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg dimension-settings" :class="!matrix.x.active && !matrix.y.active ? 'disabled' : ''">
            <h4 class="form-check">
              <input id="color" type="checkbox" v-model="matrix.c.active" class="form-check-input mt-2">
              <label for="color" class="form-check-label">Farbdimension</label>
            </h4>
            <div v-if="matrix.c.active">
              <select v-model="matrix.c.title" class="form-control">
                <option value="" disabled="disabled">Dimension wählen</option>
                <option v-for="(key, index) in keys" :value="key.title" :key="index">{{ key.title }}</option>
              </select>
              <div class="form-check">
                <input id="invert-c" type="checkbox" v-model="matrix.c.invert" class="form-check-input">
                <label for="invert-c" class="form-check-label">Farbe invertieren</label>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg dimension-settings">
            <h4>Dimensionen hinzufügen</h4>
            <ul>
              <li v-for="(key, index) in keys" class="mb-1" :key="index">
                <div class="row">
                  <div class="col-10 pr-1">
                    <input type="text" v-model="key.title" class="form-control form-control-sm">
                  </div>
                  <div class="col-2 px-1">
                    <button class="btn btn-danger btn-sm" @click="removeKey(index)">
                      <i class="fal fa-minus"></i>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-10 pr-1">
                    <input v-model="newKey.title" placeholder="Key hinzufügen" type="text" @change="addKey"
                           class="form-control form-control-sm">
                  </div>
                  <div class="col-2 px-1">
                    <button class="btn btn-success btn-sm" @click="addKey">
                      <i class="fal fa-plus"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BrainstormMatrix',
  components: {},
  directives: {},
  props: {
    rootNode: Object,
  },
  data() {
    return {
      background: true,
      matrix: {
        x: {
          title: "",
          active: false,
          invert: false,
          min: 1,
          max: 10
        },
        y: {
          title: "",
          active: false,
          invert: false,
          min: 1,
          max: 10
        },
        d: {
          title: "",
          active: false,
          numeric: false,
        },
        c: {
          title: "",
          active: false,
          invert: false,
        },
      },
      colors: {
        bgColors: [
          "45CAFF",
          "FF1B6B",
        ],
        nodeColors: [
          "333333",
          "c0c0c0",
        ]
      },
      draggedEl: null,
      draggedOver: {
        x: null,
        y: null
      },
      mobileDraggingActive: false,
      newKey: {
        title: "",
        type: "number"
      }
    }
  },
  computed: {
    nodes() {
      return this.rootNode.children;
    },
    keys() {
      return this.rootNode.keys.filter(k => k.type === 'number');
    },
    unmappedNodes() {
      return this.nodes.filter(n => {
        return ['x', 'y'].reduce((acc, dim) => {
          dim = this.matrix[dim];
          let dimActive = dim.active && dim.title !== "";
          let hasNoValue = n[dim.title] === 0 || n[dim.title] === undefined ;
          return dimActive ? acc || hasNoValue : acc;
        }, false);
      });
    },
    mapX() {
      return this.getMapRange('x');
    },
    mapY() {
      return this.getMapRange('y');
    },
    mapC() {
      return this.getMapRange('c');
    },
  },
  watch: {
    keys(newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.fillDimensions();
      }
    },
  },
  methods: {
    selectNode(node) {
      if (node.children.length) {
        this.$emit('selectNode', node);
      }
    },
    addKey() {
      this.rootNode.keys.push(JSON.parse(JSON.stringify(this.newKey)));
      this.newKey = {
        title: "",
        type: "number"
      };
      setTimeout(() => {
        this.selectedKey = this.keys[this.keys.length - 1];
      }, 100);
      this.$emit('update', {update: this.rootNode});
    },
    removeKey(index) {
      for (let dimension of Object.keys(this.matrix)) {
        if (this.matrix[dimension].title === this.rootNode.keys[index]) {
          this.matrix[dimension].title = "";
        }
      }
      this.rootNode.keys.splice(index, 1);
      this.$emit('update', {update: this.rootNode});
    },
    mapNodes(x, y) {
      let map = {x, y};
      return this.nodes.filter(n => {
        return ['x', 'y'].reduce((acc, dim) => {
          return acc && (this.matrix[dim].active ^ n[this.matrix[dim].title] !== map[dim])
        }, true);
      });
    },
    dragStartMobile(node) {
      if (!this.clientHasDragging) {
        this.mobileDraggingActive = true;
        this.draggedEl = this.draggedEl === node ? null : node;
      }
    },
    dragEndMobile(target) {
      if (!this.clientHasDragging) {
        this.mobileDraggingActive = false;
        this.draggedOver = target;
        this.mapDraggedNode();
      }
    },
    mapDraggedNode() {
      if (this.draggedOver === this.unmappedNodes) {
        this.$set(this.draggedEl, this.matrix.x.title, undefined);
        this.$set(this.draggedEl, this.matrix.y.title, undefined);
      } else {
        if (this.matrix.x.active && this.matrix.x.title !== "") {
          this.$set(this.draggedEl, this.matrix.x.title, this.draggedOver.x);
        }
        if (this.matrix.y.active && this.matrix.y.title !== "") {
          this.$set(this.draggedEl, this.matrix.y.title, this.draggedOver.y);
        }
      }

      this.$set(this.rootNode, 'children', this.rootNode.children);

      this.draggedEl = this.rootNode;
      this.draggedOver = {x: null, y: null};

      this.$emit('update', {update: this.rootNode});
    },
    getMapRange(dimension) {
      if (dimension === 'c') {
        let range = this.nodes.reduce((acc, n) => {
          let val = parseInt(n[this.matrix[dimension].title]);
          return val >= 0 ? [Math.max(acc[0], val), Math.min(acc[1], val)] : acc
        }, [-Infinity, Infinity]);

        if (range[0] === -Infinity && range[1] === Infinity) {
          return {
            range: 1,
            upper: 0,
            lower: 0
          }
        } else {
          return {
            range: range[0] - range[1] + 1,
            upper: range[0],
            lower: range[1]
          }
        }
      }

      if (this.matrix[dimension].active) {
        return {
          range: this.matrix[dimension].max - this.matrix[dimension].min + 1,
          upper: this.matrix[dimension].max,
          lower: this.matrix[dimension].min,
        }
      } else {
        return {
          range: 1,
          upper: 0,
          lower: 0
        }
      }
    },
    getBgColor(x, y) {
      return this.getMapColor([x, y], [this.mapX.range, this.mapY.range], 'bgColors')
    },
    getNodeColor(node) {
      if (!this.matrix.c.active || !this.matrix.c.title) return;
      let val = parseInt(node[this.matrix.c.title]);
      let colorVal = this.matrix.c.invert ? (this.mapC.upper - val + 1) : (val);
      return this.getMapColor([colorVal], [this.mapC.range], 'nodeColors');
    },
    getMapColor(values, ranges, colorKey) {
      let value = values.reduce((acc, v) => acc + v / values.length, 0);
      let range = Math.max.apply(null, ranges);
      let colors = this.colors[colorKey];

      let color = "#";
      for (let i = 0; i < 6; i += 2) {
        let topVal = parseInt(colors[0].slice(i,i+2), 16)
        let bottomVal = parseInt(colors[1].slice(i,i+2), 16)

        color += Math.round(Math.ceil((topVal - bottomVal + 1) / range) * value + bottomVal).toString(16).padStart(2, '0');
      }

      if (colorKey === 'bgColors') color += "88";

      return color;
    },
    showMapAdd(x, y) {
      let isSameEl = (this.draggedEl[this.matrix.x.title] === this.draggedOver.x && this.draggedEl[this.matrix.y.title] === this.draggedOver.y);
      let isOver =  this.draggedOver.x === x && this.draggedOver.y === y;
      return !isSameEl && isOver;
    },
    fillDimensions(keys) {
      keys = keys || this.keys;
      let dimensions = ['d', 'x', 'y', 'c'];
      for (let i = 0; i < Math.min(dimensions.length, keys.length); i++) {
        this.matrix[dimensions[i]].active = true;
        this.matrix[dimensions[i]].title = keys[i].title;
      }
    },
  },
  beforeMount() {
    this.fillDimensions();
    this.draggedEl = this.rootNode;
  }
}
</script>

<style lang="scss" scoped>
.dimension-settings.disabled {
  color: var(--gray) !important;
  pointer-events: none;
}

table.table {
  position: relative;

  td {
    position: relative;
    border: none !important;
    box-shadow: inset 0 0 1px rgba(white, .6);

    &.border-right {
      border-right: 3px dashed white !important;
    }

    &.border-left {
      border-left: 3px dashed white !important;
    }

    &.border-top {
      border-top: 3px dashed white !important;
    }

    &.border-bottom {
      border-bottom: 3px dashed white !important;
    }
  }

  .y-label {
    position: absolute;
    top: 50%;
    left: calc(100% + 2rem);
    transform-origin: 0 0;
    transform: rotate(90deg) translateX(-50%);
  }
}
  .map-add {
    position: absolute;
    $padding: .25rem;
    top: $padding;
    left: $padding;
    right: $padding;
    bottom: $padding;
    width: auto;
    padding: 0;
    margin: 0;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map-node {
    position: relative;

    .hover-menu {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      opacity: .8;
      background: #333333;
      z-index: 1050;

      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;

      .btn-group {
        width: 100%;
      }
    }
    &.scored:hover {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;

      .hover-menu {
        display: flex;
      }
    }

    .hover-overlay-mobile {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%,-50%);
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      opacity: .8;
      background: #333333;
      z-index: 1050;
    }
    &:hover {
      .hover-overlay-mobile {
        display: flex;
      }
    }
  }
  .dots {
    display: flex;
    margin: 0;
    padding: 0;
    text-align: left;
    min-height: 0;
    height: 6px;
  }
  .dot {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 1rem;
    margin: 0;
    margin-right: 3px;
    padding: 0;
    background: white;
  }
</style>