<template>
  <div>
    <div class="scoring-container">
      <div class="row mb-4">
        <div class="col-sm-6">
          <h4>Scoring-Dimension auswählen</h4>
          <select v-model="selectedKey" class="form-control">
            <option disabled value="">{{ keys.length ? 'Dimension wählen' : 'Noch keine Dimension vorhanden' }}</option>
            <option v-for="(key, index) in keys" :key="index" :value="key">
              {{ key.title }}
            </option>
          </select>
        </div>
        <div class="col-sm-6">
          <h4>Scoring-Dimensionen hinzufügen</h4>
          <div class="row">
            <div class="col">
              <input v-model="newKey.title" class="form-control" placeholder="Key hinzufügen" type="text" @change="addKey">
            </div>
            <div class="col-auto">
              <button class="btn btn-success" @click="newKey.type = 'number'; $emit('addKey')">
                <i class="fal fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div v-for="(cluster, index) in clusters" :key="index" class="col-4 mb-4">
              <BrainstormScoreItem :cluster="cluster" :selected-key="selectedKey" @update="update($event, index)"></BrainstormScoreItem>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrainstormScoreItem from "@/components/BrainstormScoreItem";

export default {
  name: 'BrainstormScoring',
  components: {
    BrainstormScoreItem
  },
  directives: {},
  props: {
    rootNode: Object,
  },
  data() {
    return {
      selectedKey: {
        title: "",
        type: "number"
      },
      newKey: {
        title: "",
        type: "number"
      }
    }
  },
  computed: {
    keys() {
      return this.rootNode.keys ? this.rootNode.keys.filter(k => k.type === 'number') : [];
    },
    clusters() {
      return this.rootNode.children;
    }
  },
  watch: {},
  methods: {
    update($event, index) {
      this.rootNode.children.splice(index, 1, $event.update);
      this.$emit('update', {update: this.rootNode});
    },
    addKey() {
      this.rootNode.keys.push(JSON.parse(JSON.stringify(this.newKey)));
      this.newKey = {
        title: "",
        type: "number"
      };
      setTimeout(() => {
        this.selectedKey = this.keys[this.keys.length - 1];
      }, 100);
      this.$emit('update', {update: this.rootNode});
    },
    addToCluster(cluster, $event) {
      cluster.children.push({
        title: $event,
        children: []
      });
      this.$emit('update', {update: this.rootNode});
    },
    removeFromCluster(cluster, $event) {
      let temp = cluster.children[$event];
      cluster.children.splice($event, 1);
      this.rootNode.looseItems.push(temp)
      this.$emit('update', {update: this.rootNode});
    },
    removeCluster(cluster) {
      this.rootNode.looseItems.concat(cluster.children);
      let index = this.clusters.findIndex(n => n === cluster);
      this.clusters.splice(index, 1);
      this.$emit('update', {update: this.rootNode});
    },
  },
  beforeMount() {
    if (!this.rootNode.keys) {
      this.rootNode.keys = [];
    } else if (this.keys.length > 0) {
      this.selectedKey = this.rootNode.keys[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.clustering-container {
  position: relative;

  .sticky-top {
    min-height: 80vh;

    .add-overlay {
      position: absolute;
      top: 5rem;
      left: 0;
      right: 0;
      bottom: 0;
      background: var(--dark);
      opacity: .5;

      i.fad {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>