<template>
  <div>
    <div class="row">
      <div v-for="(node, index) in rootNode.children" :key="index + 'c'" class="col-sm-6 col-lg-3">
        <div class="p-1">
          <div class="border rounded p-2">
            <p class="mb-0">
              <i class="fal fa-arrow-right text-primary mr-1" @click="$emit('selectNode', [index])"></i>
              <input v-model="node.title" class="naked" type="text" @change="changeTitle">
              <i class="fal fa-minus-circle text-danger float-right mt-1" @click="removeFromChildren(index)"></i>
            </p>
          </div>
        </div>
      </div>
      <div v-for="(node, index) in rootNode.looseItems" :key="index + 'l'" class="col-sm-6 col-lg-3">
        <div class="p-1">
          <div class="border rounded p-2">
            <p class="mb-0">
              <input v-model="node.title" class="naked" type="text" @change="changeTitle">
              <i class="fal fa-minus-circle text-danger float-right mt-1" @click="removeFromNewNodes(index)"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 p-3">
        <div class="input-group">
          <input v-model="newNode.title" class="form-control" placeholder="Neues Item hinzufügen" type="text" @change="addToNewNodes">
          <button class="btn btn-success" @click="addToNewNodes">
            <i class="fal fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BrainstormCollect',
  components: {},
  directives: {},
  props: {
    rootNode: Object,
  },
  data() {
    return {
      newNode: {
        title: "",
        children: [],
        keys: [],
        looseItems: []
      },
    }
  },
  computed: {
    newClusters() {
      return this.rootNode.children;
    },
    looseItems() {
      return this.rootNode.looseItems;
    }
  },
  watch: {},
  methods: {
    changeTitle() {
      this.$emit('update', {update: this.rootNode});
    },
    addToNewNodes() {
      if (!this.rootNode.looseItems) this.rootNode.looseItems = [];

      this.rootNode.looseItems.push(JSON.parse(JSON.stringify(this.newNode)));
      this.newNode.title = "";
      this.$emit('update', {update: this.rootNode});
    },
    removeFromNewNodes(index) {
      this.rootNode.looseItems.splice(index, 1);
      this.$emit('update', {update: this.rootNode});
    },
    removeFromChildren(index) {
      if (confirm('Remove ' + this.rootNode.children[index].title + ' from ' + this.rootNode.title + '?')) {
        this.rootNode.children.splice(index, 1);
        this.$emit('update', {update: this.rootNode});
      }
      this.$emit('update', {update: this.rootNode});
    },
  },
  beforeMount() {
  }
}
</script>

<style lang="scss" scoped>

</style>