<template>
  <div class="card">
    <div class="card-header">
      <input v-model="cluster.title" class="naked form-control" placeholder="Cluster benennen..." type="text">
      <div v-if="selectedKey" class="mt-2">
        <div class="dots">
          <span v-for="d in Array.from({length: cluster[selectedKey.title]},(x, i) => i)" :key="d" class="dot active"></span>
        </div>
      </div>
    </div>
    <div v-if="selectedKey" class="card-body">
      <div class="btn-group btn-block">
        <button class="btn btn-dark" @click="score(-1)"><i class="fad fa-minus-circle"></i></button>
        <button class="btn btn-dark" @click="score(1)"><i class="fad fa-plus-circle"></i></button>
      </div>
    </div>
    <div class="card-footer">
      <ul class="list-group">
        <template v-for="(child, childIndex) in cluster.children">
          <li :key="childIndex" class="list-group-item">
            <input v-model="child.title" class="naked form-control form-control-sm" type="text">
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BrainstormScoreItem',
  components: {},
  directives: {},
  props: {
    cluster: Object,
    selectedKey: Object
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    score(value) {
      let newVal = this.cluster[this.selectedKey.title] >= 0 ? this.cluster[this.selectedKey.title] + value : value;
      this.$set(this.cluster, this.selectedKey.title, newVal);
      this.$emit('update', {update: this.cluster});
    },
    add($event) {
      if ($event.target.value) {
        this.$emit('add', $event.target.value);
        $event.target.value = "";
      }
    },
    removeFromCluster(index) {
      this.$emit('remove', index);
    },
    removeCluster() {
      this.$emit('removeCluster');
    },
  },
  beforeUpdate() {
    if (this.selectedKey && !this.cluster[this.selectedKey.title]) {
      this.cluster[this.selectedKey.title] = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.card-header .form-control {
  font-weight: 500;
  font-size: 1rem;
}

$dotSize: 1rem;
.dots {
  display: flex;
  text-align: left;
  min-height: 0;
  height: $dotSize;
  margin: $dotSize/2 0;
}

.dot {
  display: inline-block;
  width: $dotSize;
  height: $dotSize;
  border-radius: 100%;
  margin: 0;
  margin-right: $dotSize / 2;
  padding: 0;
  border: 1px solid var(--primary);

  &.active {
    background: var(--primary);
  }
}
</style>
