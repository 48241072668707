<template>
  <span class="in-place-input" :ref="'wrapper-' + uniqueId">
    <span v-show="!editMode && !forceEdit"
          @click="edit"
    >
      {{ value }}
    </span>
    <input v-show="editMode || forceEdit"
           :ref="'input-' + uniqueId"
           type="text"
           :id="ids"
           :class="classes"
           :value="value"
           :placeholder="placeholder"
           :required="required"
           @keyup.enter="change"
           @change="change"
    >
  </span>
</template>

<script>
export default {
  name: 'InPlaceInput',
  components: {},
  directives: {},
  props: {
    value: String,
    ids: String,
    classes: String,
    validation: Function,
    required: Boolean,
    placeholder: String,
    forceEdit: Boolean
  },
  data() {
    return {
      editMode: false,
      uniqueId: this.generateId()
    }
  },
  computed: {},
  watch: {},
  methods: {
    edit($event) {
      $event.stopPropagation();

      this.editMode = true;

      setTimeout(() => {
        if (this.$refs['input-' + this.uniqueId]) {
          this.$refs['input-' + this.uniqueId].focus();
          window.addEventListener("click", clickAway);
        }
      }, 1);

      const clickAway = (event) => {
        if (!this.$refs['input-' + this.uniqueId]) {
          window.removeEventListener("click", clickAway);
        }
        if (event.path[1] !== this.$refs['wrapper-' + this.uniqueId]) {
          this.editMode = false;
          this.$emit("input", this.$refs['input-' + this.uniqueId].value);
          this.$emit("change", this.$refs['input-' + this.uniqueId].value);
          window.removeEventListener("click", clickAway);
        }
      }
    },
    change($event) {
      this.editMode = false;
      this.$emit("input", $event.target.value);
      this.$emit("change", $event);
    }
  }
}
</script>

<style lang="scss" scoped>
.in-place-input {
  display: inline-flex;
  flex-wrap: nowrap;
  width: 100%;
  min-height: 1em;

  span, input {
    width: 100%;
  }
}
</style>