<template>
  <div>
    <p :class="'h' + (level+1)">
      {{ index + 1 }}. <input type="text" v-model="node.title" class="naked w-75"> ({{ node.children.length }})
      <i class="fal fa-minus-circle text-danger float-right" @click="remove"></i>
    </p>

    <div>
      <div class="row">
        <div v-for="(key, index) in keys" class="col-sm-6 col-md-3" :key="index">
          <label>{{ key.title }}</label>
          <input :type="key.type" v-model="node[key.title]" placeholder="Key bearbeiten" class="form-control">
        </div>
      </div>

      <button @click="hideChildren = !hideChildren" class="btn btn-block btn-light my-3">
        <i class="far" :class="hideChildren ? 'fa-caret-down' : 'fa-caret-up'"></i>
      </button>

      <ul v-show="!hideChildren" class="list-group">
        <li class="list-group-item list-group-item-sm">
          <BrainstormEditItem v-for="(child, childIndex) in node.children"
                    :node="child"
                    :index="childIndex"
                    :level="level+1"
                    :keys="keys"
                    @remove="propagateRemove($event)"
                    @add="propagateAdd($event)"
                    :key="childIndex">
          </BrainstormEditItem>
        </li>
        <li class="list-group-item">
          <input type="text" @change="add($event)" placeholder="Item hinzufügen" class="form-control form-control-sm border-0">
        </li>
      </ul>
      <hr>
    </div>
  </div>
</template>

<script>
import BrainstormEditItem from "@/components/BrainstormEditItem";

export default {
  name: 'BrainstormEditItem',
  components: {
    BrainstormEditItem
  },
  directives: {},
  props: {
    node: Object,
    index: Number,
    level: Number,
    keys: Array,
  },
  data() {
    return {
      hideChildren: true
    }
  },
  computed: {},
  watch: {},
  methods: {
    add($event) {
      let val = parseInt($event.target.value) || $event.target.value;
      this.$emit('add', [this.index, val]);
      $event.target.value = "";
    },
    propagateAdd($event) {
      this.$emit('add', [this.index].concat($event));
    },
    remove() {
      this.$emit('remove', [this.index]);
    },
    propagateRemove($event) {
      this.$emit('remove', [this.index].concat($event));
    }
  },
  beforeUpdate() {
    for (let key of this.keys) {
      this[key] = "";
    }
  }
}
</script>

<style scoped></style>