<template>
  <div id="brainstorm-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <button class="btn btn-primary mb-5" @click="addBrainstorm">Neuen Brainstorm erstellen</button>

    <IntroBanner v-if="!(brainstorm.children && brainstorm.children.length)" @click="addBrainstorm"></IntroBanner>

    <div v-if="selectedNode !== brainstorm" class="row mb-3">
      <div class="col-auto">
        <button class="btn btn-light" @click="goToOverview">
          Zur Übersicht zurückkehren
        </button>
      </div>
      <div class="col-auto">
        <div v-if="brainstorm.children.length > 0" class="form-group">
          <select id="brainstorm-select" class="form-control" @change="selectNode($event.target.value)">
            <option :selected="selectedNode === brainstorm" :value="['root']">
              {{ selectedNode === brainstorm ? 'Früheren Brainstorm laden' : 'Zurück zur Übersicht' }}
            </option>
            <option v-for="(step, index) in followPath" :key="index + 'p'" :selected="selectedNode === step.node" :value="[step.path]">
              {{ step.pathTitle }}
            </option>
            <option v-for="(node, index) in selectedNode.children" :key="index + 'n'" :selected="selectedNode === node" :value="[index]">
              {{ selectionPrefix }} {{ node.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="!loading && brainstorm.children.length" class="form-row">
      <div v-if="selectedNode === brainstorm" class="col">
        <h3 class="mt-2">Übersicht</h3>
      </div>
      <div v-else class="col">
        <input v-model="selectedNode.title" class="form-control form-control-lg mb-3" placeholder="Hier das Thema eingeben" type="text">
      </div>
    </div>

    <div v-if="selectedNode !== brainstorm" class="tool-selection row">
      <div class="col">
        <div class="btn-group btn-block">
          <button :class="step === 'collect' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('collect')">
            Collect
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['collect']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'cluster' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('cluster')">
            Cluster
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['cluster']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'score' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('score')">
            Score
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['score']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'matrix' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('matrix')">
            Map
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['matrix']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'connect' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('connect')">
            Connect
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['connect']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
        </div>
      </div>
      <div v-if="isAdmin && !adminOverride" class="col-3">
        <div class="btn-group btn-block">
          <button :class="step === 'edit' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('edit')">
            Edit
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['edit']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'import' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('import')">
            Import
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['import']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
          <button :class="step === 'export' ? 'btn-primary' : 'btn-light'" class="btn btn-tool-selection" @click="goToStep('export')">
            Export
            <span class="user-indicators">
              <span v-for="(user, index) in toolUsers['export']" :key="index" :style="'background:' + user.settings.chatColor" class="user-indicator"></span>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!loading && brainstorm.children.length" class="mt-3">
      <div v-if="selectedNode === brainstorm">
        <h4>Deine Brainstorms</h4>
        <div class="row">
          <div v-for="(node, index) in brainstorm.children" :key="index + 'c'" class="col-sm-6 col-lg-3">
            <div class="p-1">
              <div class="border rounded p-2">
                <p class="mb-0">
                  {{ node.title }}
                  <i class="fal fa-minus-circle text-danger float-right mt-1" @click="removeBrainstorm(index)"></i>
                </p>
                <hr class="my-2">
                <p class="px-2 mb-0">
                  {{ node.children.length }} Cluster <br>
                  {{ node.keys.length }} Dimensionen
                </p>
                <button class="btn btn-primary btn-block mt-2" @click="selectNode(['root', index])">
                  <i class="fal fa-arrow-right"></i> Öffnen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="step === 'collect'">
        <h3 v-if="selectedNode !== brainstorm">Collect</h3>
        <BrainstormCollect :root-node="selectedNode" @selectNode="selectNode($event)" @update="updateBrainstorm($event)"></BrainstormCollect>
      </div>
      <div v-else-if="step === 'cluster'">
        <h3>Cluster</h3>
        <BrainstormClustering :root-node="selectedNode" @selectNode="selectNode($event)" @update="updateBrainstorm($event)"></BrainstormClustering>
      </div>
      <div v-else-if="step === 'score'">
        <h3>Score</h3>
        <BrainstormScoring :root-node="selectedNode" @update="updateBrainstorm($event)"></BrainstormScoring>
      </div>
      <div v-else-if="step === 'matrix'">
        <h3>Matrix</h3>
        <BrainstormMatrix :root-node="selectedNode" @update="updateBrainstorm($event)"></BrainstormMatrix>
      </div>
      <div v-else-if="step === 'connect'">
        <h3>Connect</h3>
        <p>Wähle zwei Knoten aus dem Graph um sie zu verbinden.</p>
        <NetworkGraph :links="network.links"
                      :nodes="network.nodes"
                      :selected-node="newLink"
                      @selectLink="removeLink($event)"
                      @selectNode="linkNodes($event)"
        ></NetworkGraph>
        <div v-if="newLink" class="row align-items-center">
          <div class="col-sm-5">
            <BrainstormEditItem :index="0"
                                :keys="[]"
                                :level="brainstorm.depth+2"
                                :node="brainstorm"
            ></BrainstormEditItem>
          </div>
          <div class="col-2 text-center">
            <i class="fad fa-arrows-h fa-2x mb-4"></i><br>
            <i class="fad fa-times-circle text-danger fa-2x" @click="newLink = null"></i>
          </div>
        </div>
      </div>

      <div v-else-if="step === 'import'">
        <h3>Import</h3>
        <h4>.json-Datei importieren</h4>
        <textarea v-model="brainstormImport" class="form-control" name="brainstorm-import" placeholder="Hier Objekt im JSON-Format einfügen"></textarea>
        <button class="btn btn-primary my-3" type="submit" @click="importBrainstorm">Import</button>
      </div>
      <div v-else-if="step === 'edit'">
        <h3>Edit</h3>

        <div class="row">
          <div class="col-4">
            <h4>Item-Attribute</h4>
            <ul class="list-unstyled">
              <li v-for="(key, index) in selectedNode.keys" class="mb-1" :key="index">
                <div class="row">
                  <div class="col-6 pr-1">
                    <input type="text" v-model="key.title" class="form-control form-control-sm">
                  </div>
                  <div class="col-4 px-1">
                    <select v-model="key.type" class="form-control form-control-sm">
                      <option value="text">Text</option>
                      <option value="number">Zahl</option>
                      <option value="checkbox">Checkbox (On/Off)</option>
                    </select>
                  </div>
                  <div class="col-2 px-1">
                    <button @click="removeKey(index)" class="btn btn-danger btn-sm">
                      <i class="fal fa-minus"></i>
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <div class="row">
                  <div class="col-6 pr-1">
                    <input type="text" v-model="newKey.title" placeholder="Key hinzufügen" class="form-control form-control-sm">
                  </div>
                  <div class="col-4 px-1">
                    <select v-model="newKey.type" class="form-control form-control-sm">
                      <option value="text">Text</option>
                      <option value="number">Zahl</option>
                      <option value="checkbox">Checkbox (On/Off)</option>
                    </select>
                  </div>
                  <div class="col-2 px-1">
                    <button @click="addKey" class="btn btn-success btn-sm">
                      <i class="fal fa-plus"></i>
                    </button>
                  </div>
                </div>
              </li>
            </ul>

            <button class="btn btn-danger" @click="removeBrainstorm">Brainstorm löschen</button>
          </div>
          <div class="col-8">
            <ul class="list-group">
              <li class="list-group-item">
                <BrainstormEditItem v-for="(node,index) in selectedNode.children"
                          :node="node"
                          :index="index"
                          :level="1"
                          :keys="selectedNode.keys"
                          @remove="removeFromTree($event)"
                          @add="addToTree($event)"
                          :key="index">
                </BrainstormEditItem>
              </li>
              <li class="list-group-item">
                <input type="text" @change="add([$event.target.value])" placeholder="Top-Level Item hinzufügen..."
                       class="form-control form-control-sm border-0">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else-if="step === 'export'">
        <h3>Export</h3>
        <div>
          <textarea id="selectedNode-output" :value="JSON.stringify(nodeExport)" name="selectedNode-output" readonly rows="30"
                    class="form-control h-100"></textarea>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BrainstormMatrix from "@/components/BrainstormMatrix";
import BrainstormClustering from "@/components/BrainstormClustering";
import BrainstormEditItem from "@/components/BrainstormEditItem";
import NetworkGraph from "@/components/NetworkGraph";
import BrainstormScoring from "@/components/BrainstormScoring";
import BrainstormCollect from "@/components/BrainstormCollect";
import IntroBanner from "@/components/IntroBanner";

export default {
  name: 'Brainstorm',
  components: {
    IntroBanner,
    BrainstormCollect,
    BrainstormScoring,
    BrainstormClustering,
    BrainstormMatrix,
    BrainstormEditItem,
    NetworkGraph,
  },
  data() {
    return {
      loading: false,
      step: "collect",
      brainstormImport: "",
      selectedNode: {
        id: this.generateId(),
        title: "",
        children: [],
        looseItems: [],
        keys: [
          { title: "Score", type: "number" },
          { title: "Aufwand", type: "number" },
          { title: "Nutzen", type: "number" },
        ],
        links: []
      },
      newNode: {
        title: "",
        children: [],
        keys: [
          { title: "Score", type: "number" },
          { title: "Aufwand", type: "number" },
          { title: "Nutzen", type: "number" },
        ],
        looseItems: []
      },
      newKey: {
        title: "",
        type: "number"
      },
      newLink: null,
      path: ["root"],
    }
  },
  computed: {
    toolUsers() {
      let steps = {
        'collect': [],
        'cluster': [],
        'score': [],
        'matrix': [],
        'connect': [],
        'edit': [],
        'import': [],
        'export': [],
      }
      Object.keys(steps).forEach(tool => {
        steps[tool] = this.socket.activeUsers.users.filter(u => u.route && u.route.indexOf(tool) >= 0);
      });
      return steps;
    },
    brainstorm() {
      return this.project.brainstorm;
    },
    nodes() {
      return this.selectedNode.children;
    },
    network() {
      let nodes = [];
      let links = [];

      let f = (node, depth) => {
        node.depth = depth;
        node.status = 200;
        node._labelClass = depth < 2 ? 'font-weight-bold' : 'text-muted';
        nodes.push(node);
        if (node.children.length) {
          node.children.forEach(child => {
            child.id = nodes.length;
            links.push({
              id: links.length,
              sid: node.id,
              tid: child.id,
            });
            f(child, depth + 1);
          });
        }
      }

      for (let child of this.selectedNode.children) {
        f(Object.assign(child, { id: nodes.length }), 1);
      }

      links = links.concat(this.selectedNode.links.map(link => {
        link._resetColor = "#f62473";
        link._svgAttrs = {"stroke-dasharray": "5px", "stroke": "#f62473"};
        return link;
      }));

      return {
        nodes,
        links
      }
    },
    nodeExport() {
      return this.filterNodeData(this.selectedNode);
    },
    followPath() {
      let ref = this.brainstorm;
      let depth = 0;
      let currPath = ["root"];
      return this.loading ? currPath : this.path.slice(1).map(index => {
        if (index === 'root') return;
        depth++;
        currPath.push(index);
        let node = ref.children[index];
        ref = node;
        let prefix = "";
        for (; prefix.length < depth;) prefix += "-";
        /*console.log('followpath', this.path, currPath, node);*/
        return {
          node,
          path: JSON.parse(JSON.stringify(currPath)),
          pathTitle: prefix + " " + node.title
        }
      })
    },
    selectionPrefix() {
      let prefix = "";
      for (; prefix.length < this.path.length + 1;) prefix += "-";
      return prefix;
    }
  },
  watch: {
    brainstorm: {
      deep: true,
      handler: function (newVal) {
        this.selectNode(this.path, newVal);
      }
    }
  },
  methods: {
    goToOverview() {
      this.selectedNode = this.brainstorm;
      this.step = 'collect';
      this.path = ["root"];
    },
    goToStep(step) {
      this.step = step;
      if (this.$route.params.tool !== step) {
        this.$router.replace('/brainstorm/' + step);
      }
    },
    selectNode(path, ref) {
      path = JSON.parse(JSON.stringify(path));

      if (typeof path === "string") {
        path = JSON.parse("[" + path.replace("root", "\"root\"") + "]");
      }

      if (path.length === 1 && path[0] === "root") {
        if (ref) {
          this.selectedNode = ref;
        } else {
          this.goToOverview();
        }
      } else {
        if (path.length === 1) {
          this.selectedNode = this.selectedNode.children[path[0]];
          this.path.push(path[0]);
        } else {
          if (!ref) {
            ref = this.project.brainstorm;
          }
          let index;
          for (index of path) {
            if (index === 'root') continue;
            ref = ref.children[index];
          }
          this.selectedNode = ref;
          this.path = path;
        }
      }
      this.$forceUpdate();
    },
    addBrainstorm() {
      this.project.brainstorm.children.push(JSON.parse(JSON.stringify(this.newNode)));
      this.newNode = {
        id: this.generateId(),
        title: "",
        children: [],
        looseItems: [],
        keys: [
          { title: "Score", type: "number" },
          { title: "Aufwand", type: "number" },
          { title: "Nutzen", type: "number" },
        ],
        links: []
      };
      this.updateBrainstorm();
      this.goToOverview();
      this.selectNode(['root', this.project.brainstorm.children.length-1])
    },
    updateBrainstorm() {
      console.debug('updateBrainstorm', this.path);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.project.brainstorm});
    },
    saveBrainstorm(redirect) {
      return this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.filterNodeData(this.brainstorm)})
          .then(res => {
            console.debug('saveBrainstorm res', res.project.brainstorm);
            if (redirect) {
              this.goToOverview();
            }
            return res;
          });
    },
    removeBrainstorm(index) {
      if (confirm('Remove "' + this.project.brainstorm.children[index].title + '" from Brainstorms?')) {
        this.project.brainstorm.children.splice(index, 1);
        this.saveBrainstorm(true);
      }
    },
    linkNodes(node) {
      if (node === this.newLink) {
        this.newLink = {};
      } else if (this.newLink) {
        this.selectedNode.links.push({
          id: this.network.links.length,
          sid: this.newLink.id,
          tid: node.id
        });
        this.newLink = null;
      } else {
        this.newLink = node;
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.brainstorm});
    },
    removeLink(link) {
      if (link._resetColor) {
        let index = this.selectedNode.links.findIndex(l => l.id === link.id);
        this.selectedNode.links.splice(index, 1);
      }
      this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.brainstorm});
    },
    addToTree(path) {
      let newNode = {
        title: path.pop(),
        children: [],
        keys: [],
        looseItems: []
      };
      if (newNode.title === "") return;

      let temp = JSON.parse(JSON.stringify(this.selectedNode));
      let ref = temp.children;
      let childIndex;
      for (childIndex of path) {
        ref = ref[childIndex].children;
      }

      ref.push(newNode);
      this.selectedNode = temp;
      this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.brainstorm});
    },
    removeFromTree(path) {
      let temp = JSON.parse(JSON.stringify(this.selectedNode));
      let ref = temp.children;
      let childIndex;
      for (childIndex of path.slice(0, -1)) {
        ref = ref[childIndex].children;
      }
      ref.splice(path.pop(), 1);
      this.selectedNode = temp;
      this.$store.dispatch('project/updateProjectByProp', {prop: 'brainstorm', data: this.brainstorm});
    },
    importBrainstorm() {
      let newNode = JSON.parse(this.brainstormImport);
      if (!newNode.id) {
        newNode.id = this.generateId();
      }
      this.brainstorm.children.push(newNode);
      this.saveBrainstorm(true);
    },
    filterNodeData(node) {
      node = JSON.parse(JSON.stringify(node));

      let f = (node) => {
        delete node.name;
        delete node.selected;
        delete node._labelClass;
        delete node._cssClass;
        delete node._cssStyle;
        delete node._size;
        delete node._color;
        delete node.vx;
        delete node.vy;
        delete node.x;
        delete node.y;
        delete node.fx;
        delete node.fy;

        if (node.children && node.children.length) {
          node.children.forEach(child => {
            f(child);
          });
        }
      }

      if (node.children) {
        for (let child of node.children) {
          f(child);
        }
      }
      if (node.links) {
        for (let link of node.links) {
          delete link.index;
          delete link.direction;
          delete link.selected;
          delete link.source;
          delete link.target;
          delete link._color;
          delete link._svgAttrs;
        }
      }
      return node;
    },
  },
  beforeMount() {
    if (this.$route.params.tool && this.step !== this.$route.params.tool) {
      this.step = this.$route.params.tool;
    }
    this.selectedNode = this.brainstorm;
  }
}
</script>

<style lang="scss">
.btn-tool-selection {
  position: relative;

  .user-indicators {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -.5rem;
    left: -.1rem;
    right: -.1rem;
    transition: all .2s;
    opacity: .8;
  }
}
</style>